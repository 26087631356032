<template>
  <wx-form-container class="wizard">
    <div class="d-flex justify-center mt-10" v-if="isFetchingAlert">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <v-form v-else ref="alertForm" lazy-validation>
      <fieldset>
        <h3 class="mb-2">{{ $t("alerts.wizard.alertTypeTitle") }}</h3>
        <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.alertTypeInfo") }}</h5>
        <wx-autocomplete v-model="kpi" label="KPI" :items="kpiItems" :rules="kpiRules" />
        <!--   CURRENT DOWNTIME KPI FIELDS   -->
        <div v-if="kpi === 'UnplannedCurrentDowntime'">
          <v-row>
            <v-col>
              <wx-autocomplete
                v-model="operator"
                :label="$t('alerts.wizard.operator')"
                :items="operatorItems"
                disabled
              />
            </v-col>
            <v-col>
              <wx-timer-duration-input
                v-model="consecutiveTime"
                :title="$t('alerts.wizard.consecutiveTime')"
                :error-messages="[]"
                :rules="consecutiveTimeRules"
              />
            </v-col>
          </v-row>
        </div>
      </fieldset>
      <fieldset>
        <h3 class="mb-2">{{ $t("alerts.wizard.targetTitle") }}</h3>
        <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.targetInfo") }}</h5>
        <wx-production-unit-multi-selector
          v-model="productionUnits"
          :available-production-units="factoryProductionUnits"
          :is-multiple-factories="false"
        />
      </fieldset>
      <fieldset>
        <h3 class="mb-2">{{ $t("alerts.wizard.deliveryMode.title") }}</h3>
        <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.deliveryMode.info") }}</h5>
        <!-- CAN ACCESS EVERY DELIVERY MODE IF ALERT CAPABILITY IS 'all' -->
        <fieldset v-if="isAllAlertTypeEnabled">
          <v-checkbox
            v-model="emailSelected"
            :label="$t('alerts.wizard.deliveryMode.email.name')"
            :rules="[() => validateAtLeastOneDeliveryMode()]"
            class="mt-0"
          />
          <fieldset v-if="emailSelected" class="ml-10">
            <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.deliveryMode.email.info") }}</h5>
            <v-combobox
              v-model="recipientEmails"
              :filter="filterRecipient"
              :hide-no-data="!recipientEmailSearch"
              :items="recipientEmailItems"
              :search-input.sync="recipientEmailSearch"
              :error-messages="emailErrorMessages"
              :rules="recipientEmailRules"
              :label="$t('alerts.wizard.deliveryMode.email.inputLabel')"
              hide-selected
              multiple
              small-chips
              filled
              clearable
              dense
              @change="onEmailChange"
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip v-bind="attrs" color="primary" :input-value="selected" label small class="my-1">
                  <span class="pr-2">
                    {{ item }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">
                    $delete
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </fieldset>

          <v-checkbox
            v-model="whatsAppSelected"
            :label="$t('alerts.wizard.deliveryMode.whatsapp.name')"
            :rules="[() => validateAtLeastOneDeliveryMode()]"
            class="mt-0"
          />
          <fieldset v-if="whatsAppSelected" class="ml-10">
            <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.deliveryMode.whatsapp.info") }}</h5>
            <v-phone-input
              ref="whatsAppPhone"
              v-model="inputWhatsappPhone"
              :label="$t('alerts.wizard.deliveryMode.whatsapp.inputLabel')"
              :all-countries="allCountries"
              :error-messages="whatsAppPhoneErrorMessages"
              country-label=""
              validate-on-blur
              @blur="onNewWhatsappPhone"
              @keydown="onWhatsappPhoneKeyDown"
            />
            <v-combobox
              v-model="recipientWhatsapp"
              :rules="recipientWhatsappRules"
              :label="$t('alerts.wizard.deliveryMode.whatsapp.listLabel')"
              hide-selected
              multiple
              small-chips
              filled
              clearable
              dense
              readonly
              :append-icon="null"
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip v-bind="attrs" color="primary" :input-value="selected" label small class="my-1">
                  <span class="pr-2">
                    {{ item }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">
                    $delete
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </fieldset>

          <v-checkbox
            v-model="teamsWebhookSelected"
            :label="$t('alerts.wizard.deliveryMode.teams.name')"
            :rules="[() => validateAtLeastOneDeliveryMode()]"
            class="mt-0"
            @change="onTeamsWebhookSelectedChanged"
          />
          <fieldset v-if="teamsWebhookSelected" class="ml-10">
            <v-row
              v-for="(teamsWebhook, index) in teamsWebhooks"
              :key="'teamsWebhooks-' + index"
              class="teams-webhook-link mb-3"
            >
              <v-col class="flex-grow-1">
                <wx-text-field
                  v-model="teamsWebhooks[index]"
                  :placeholder="$t('alerts.wizard.deliveryMode.teams.inputPlaceholder')"
                  :rules="[() => validUrl(index)]"
                  :dense="false"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="1" class="d-flex justify-end px-0 ma-0">
                <wx-btn-standard
                  :disabled="isRemoveButtonDisabled()"
                  class="btn-delete"
                  text
                  @click="deleteTeamsWebhook(index)"
                >
                  <v-icon left>mdi-close</v-icon>
                </wx-btn-standard>
              </v-col>
            </v-row>
            <wx-btn-standard text @click="addNewTeamsWebhook" class="mt-3">
              <v-icon left>mdi-plus</v-icon>
              {{ $t("alerts.wizard.deliveryMode.teams.buttonAddNewWebhook") }}
            </wx-btn-standard>
          </fieldset>
        </fieldset>
        <!-- ONLY ACCESS TO EMAIL DELIVERY MODE IF NOT 'all' -->
        <div v-else>
          <fieldset>
            <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.deliveryMode.email.info") }}</h5>
            <v-combobox
              v-model="recipientEmails"
              :filter="filterRecipient"
              :hide-no-data="!recipientEmailSearch"
              :items="recipientEmailItems"
              :search-input.sync="recipientEmailSearch"
              :error-messages="emailErrorMessages"
              :rules="recipientEmailRules"
              :label="$t('alerts.wizard.deliveryMode.email.inputLabel')"
              hide-selected
              multiple
              small-chips
              filled
              clearable
              dense
              @change="onEmailChange"
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip v-bind="attrs" color="primary" :input-value="selected" label small class="my-1">
                  <span class="pr-2">
                    {{ item }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">
                    $delete
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </fieldset>
        </div>
      </fieldset>
      <fragment v-if="alert">
        <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.alertStatus") }}</h5>
        <v-switch v-model="enabled" :label="alertStatus" color="primary" hide-details inset />
      </fragment>

      <form-footer-actions :click-submit="submit" cancel-to="toAlerts" />
    </v-form>
  </wx-form-container>
</template>

<script>
import WxAutocomplete from "@/components/ui/WxAutocomplete.vue";
import WxTimerDurationInput from "@/components/ui/WxTimerDurationInput.vue";
import WxFormContainer from "@/components/ui/WxFormContainer.vue";
import { mapActions, mapGetters } from "vuex";
import FormFooterActions from "@/components/ui/FormFooterActions.vue";
import WxProductionUnitMultiSelector from "@/components/ui/WxProductionUnitMultiSelector.vue";
import AlertService from "@/components/alerts/AlertService";
import RouteService from "@/router/RouteService";
import validations from "@/components/validations";
import I18nCountries from "@/i18nCountries";
import { getOption } from "v-phone-input";
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import WxTextField from "@/components/ui/WxTextField.vue";

function compareName(obj1, obj2) {
  if (obj1.name < obj2.name) {
    return -1;
  }
  if (obj1.name > obj2.name) {
    return 1;
  }
  return 0;
}

export default {
  name: "AlertWizard",
  components: {
    WxTextField,
    WxBtnStandard,
    WxProductionUnitMultiSelector,
    FormFooterActions,
    WxFormContainer,
    WxTimerDurationInput,
    WxAutocomplete,
  },
  props: {
    alert: {
      type: Object,
      default: () => null,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isFetchingAlert: false,
      kpi: null,
      kpiRules: [(v) => !!v || this.$t("alerts.wizard.kpiMandatoryMessage")],
      productionUnits: [],
      enabled: true,
      // CURRENT DOWNTIME KPI FIELD VALUES
      consecutiveTime: "00:15",
      operator: "greater_than_or_equal",
      // EMAIL
      emailSelected: false,
      recipientEmails: [],
      recipientEmailItems: [],
      recipientEmailSearch: null,
      emailErrorMessages: [],
      recipientEmailRules: [
        (v) => !!v || this.$t("alerts.wizard.deliveryMode.email.mandatoryMessage"),
        (v) => v.length > 0 || this.$t("alerts.wizard.deliveryMode.email.mandatoryMessage"),
      ],
      // WHATSAPP
      whatsAppSelected: false,
      inputWhatsappPhone: "",
      recipientWhatsapp: [],
      recipientWhatsappRules: [
        (v) => !!v || this.$t("alerts.wizard.deliveryMode.whatsapp.mandatoryMessage"),
        (v) => v.length > 0 || this.$t("alerts.wizard.deliveryMode.whatsapp.mandatoryMessage"),
      ],
      whatsAppPhoneErrorMessages: [],
      // TEAMS
      teamsWebhookSelected: false,
      teamsWebhooks: [""],
    };
  },
  watch: {
    recipientEmails(val, prev) {
      if (val.length === prev.length) return;

      this.recipientEmails = val.map((v) => {
        this.recipientEmailItems.push(v);
        return v;
      });
    },
    userEmails() {
      this.recipientEmailItems = this.recipientEmails.concat(this.userEmails);
    },
    activeFactory() {
      this.initializeData();
    },
    language() {
      this.onNewWhatsappPhone();
    },
    emailSelected() {
      this.$refs.alertForm.resetValidation();
      this.$refs.alertForm.validate();
    },
    whatsAppSelected() {
      this.$refs.alertForm.resetValidation();
      this.$refs.alertForm.validate();
    },
    teamsWebhookSelected() {
      this.$refs.alertForm.resetValidation();
      this.$refs.alertForm.validate();
    },
  },
  computed: {
    ...mapGetters("navigation", ["factoryUsers", "activeFactory"]),
    ...mapGetters("user", ["email", "language"]),
    isAllAlertTypeEnabled() {
      return this.activeFactory && this.activeFactory.alertCapability === "all";
    },
    allCountries() {
      const language = this.$i18n.locale;
      let translatedCountryNames = I18nCountries.getCountries(language);
      let countries = getOption("allCountries").map((countryInfo) => ({
        dialCode: countryInfo.dialCode,
        format: countryInfo.format,
        iso2: countryInfo.iso2,
        name: translatedCountryNames[countryInfo.iso2],
        priority: countryInfo.priority,
      }));
      return countries.sort(compareName);
    },
    alertStatus() {
      return this.enabled ? this.$t("user.statusActive") : this.$t("user.statusInactive");
    },
    kpiItems() {
      return [
        {
          text: this.$t("alerts.types.currentDowntime"),
          value: "UnplannedCurrentDowntime",
        },
      ];
    },
    operatorItems() {
      return [{ text: this.$t("alerts.operators.above"), value: "greater_than_or_equal" }];
    },
    userEmails() {
      return this.factoryUsers.map((u) => u.email);
    },
    factoryProductionUnits() {
      return this.activeFactory ? this.activeFactory.productionUnits : [];
    },
    consecutiveTimeRules() {
      return [
        () => !!this.consecutiveTimeToMinutes || this.$t("alerts.wizard.kpiConsecutiveMinutesInvalid"),
        () => this.consecutiveTimeToMinutes >= 5 || this.$t("alerts.wizard.kpiConsecutiveMinutesMin", { minutes: 5 }),
        () => this.consecutiveTimeToMinutes <= 1440 || this.$t("alerts.wizard.kpiConsecutiveMinutesMax", { hours: 24 }),
      ];
    },
    consecutiveTimeToMinutes() {
      if (!this.consecutiveTime || this.consecutiveTime === "") return null;
      try {
        const destructuredTime = this.consecutiveTime.split(":");
        const pattern = /\d{2}/;
        if (!destructuredTime[0]?.match(pattern) || !destructuredTime[1].match(pattern)) return null;
        const hours = parseInt(destructuredTime[0]);
        const minutes = parseInt(destructuredTime[1]);
        return hours * 60 + minutes;
      } catch (_err) {
        return null;
      }
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationSuccess", "handleHttpError"]),
    initializeData() {
      if (!this.activeFactory) return;
      if (this.alert) {
        this.recipientEmails = this.alert.recipients;
        this.recipientEmailItems = this.recipientEmails.concat(this.userEmails);
        this.recipientWhatsapp = this.alert.recipients_whats_app;
        this.teamsWebhooks = this.alert.teams_webhooks ? this.alert.teams_webhooks : [];
        this.productionUnits = this.alert.production_unit_ids.map((puId) =>
          this.factoryProductionUnits.find((pu) => pu.id === puId),
        );
        this.enabled = this.alert.enabled;
        this.initializeKpiSelection();
        this.emailSelected = this.recipientEmails.length > 0;
        this.whatsAppSelected = this.recipientWhatsapp.length > 0;
        this.teamsWebhookSelected = this.teamsWebhooks.length > 0;
      } else {
        this.recipientEmailItems = this.recipientEmails.concat(this.userEmails);
        this.productionUnits = this.factoryProductionUnits;
        this.recipientEmails.push(this.email);
      }
    },
    initializeKpiSelection() {
      if (!this.alert) return;
      switch (this.alert.type) {
        case "UnplannedCurrentDowntime":
          this.initializeUnplannedCurrentDowntime();
          break;
      }
    },
    initializeUnplannedCurrentDowntime() {
      this.kpi = this.alert.type;
      let durationMinutes = this.alert.unplanned_current_downtime_rules.duration_minutes;
      let hours = parseInt(durationMinutes / 60)
        .toString()
        .padStart(2, "0");
      let minutes = parseInt(durationMinutes % 60)
        .toString()
        .padStart(2, "0");
      this.consecutiveTime = `${hours}:${minutes}`;
    },
    onTeamsWebhookSelectedChanged() {
      if (this.teamsWebhookSelected && this.teamsWebhooks.length === 0) {
        this.teamsWebhooks.push("");
      }
    },
    isRemoveButtonDisabled() {
      return this.teamsWebhooks.length <= 1;
    },
    deleteTeamsWebhook(index) {
      this.teamsWebhooks.splice(index, 1);
    },
    addNewTeamsWebhook() {
      this.teamsWebhooks.push("");
    },
    validateAtLeastOneDeliveryMode() {
      if (!this.emailSelected && !this.whatsAppSelected && !this.teamsWebhookSelected) {
        return this.$t("alerts.wizard.deliveryMode.mandatoryMessage");
      } else {
        return true;
      }
    },
    validUrl(teamWebhookIndex) {
      let url = this.teamsWebhooks[teamWebhookIndex];
      if (!url || url.length === 0) {
        return this.$t("alerts.wizard.deliveryMode.teams.invalidTeamsWebhook");
      }
      try {
        const validUrl = new URL(url);
        // Javascript considers the URL as valid. It cleans itself minor errors
        // (example: http:/\teams.com/channel1 is rewritten as http://teams.com/channel1)
        // Lets update the original URL by the re-written one.
        let t = [...this.teamsWebhooks];
        t[teamWebhookIndex] = validUrl.href;
        this.teamsWebhooks = t;
        return true;
      } catch (err) {
        return this.$t("alerts.wizard.deliveryMode.teams.invalidTeamsWebhook");
      }
    },
    submit() {
      if (!this.activeFactory) return;
      if (!this.$refs.alertForm.validate()) return;
      let payload;
      switch (this.kpi) {
        case "UnplannedCurrentDowntime":
          payload = this.getDowntimeDurationPayload();
          break;
        default:
          return;
      }

      if (!this.alert) {
        // create the new alert
        this.createAlert(payload);
      } else {
        // update the existing alert
        this.updateAlert(payload);
      }
    },
    updateAlert(payload) {
      AlertService.updateAlert(this.activeFactory.id, this.alert.id, payload)
        .then(() => {
          this.showOperationSuccess(this.$t("alerts.successfulAlertUpdateMessage"));
          this.$router.replace(RouteService.toAlerts());
        })
        .catch((httpError) => {
          this.handleHttpError(httpError);
          this.$router.replace(RouteService.toAlerts());
        });
    },
    createAlert(payload) {
      AlertService.createAlert(this.activeFactory.id, payload)
        .then(() => {
          this.showOperationSuccess(this.$t("alerts.successfulAlertCreationMessage"));
          this.$router.replace(RouteService.toAlerts());
        })
        .catch((httpError) => {
          this.handleHttpError(httpError);
          this.$router.replace(RouteService.toAlerts());
        });
    },
    getDowntimeDurationPayload() {
      if (this.isAllAlertTypeEnabled) {
        const teamsWebhookURLS = this.teamsWebhooks.filter((link) => link.length > 0);
        return {
          type: this.kpi,
          rules: {
            duration_minutes: this.consecutiveTimeToMinutes,
          },
          recipients: this.emailSelected ? this.recipientEmails : null,
          recipients_whats_app: this.whatsAppSelected ? this.recipientWhatsapp : null,
          teams_webhooks: this.teamsWebhookSelected && teamsWebhookURLS.length > 0 ? teamsWebhookURLS : null,
          production_unit_ids: this.productionUnits.map((pu) => pu.id),
          enabled: this.enabled,
        };
      } else {
        return {
          type: this.kpi,
          rules: {
            duration_minutes: this.consecutiveTimeToMinutes,
          },
          recipients: this.recipientEmails,
          production_unit_ids: this.productionUnits.map((pu) => pu.id),
          enabled: this.enabled,
        };
      }
    },
    onEmailChange(value) {
      if (!value || value.length === 0) return;
      const lastEmailEntered = value[value.length - 1];
      if (validations.isUserEmailValid(lastEmailEntered) && this.emailErrorMessages.length > 0) {
        this.emailErrorMessages = [];
      } else if (!validations.isUserEmailValid(lastEmailEntered)) {
        this.emailErrorMessages = [this.$t("alerts.wizard.deliveryMode.email.invalidMessage")];
        this.recipientEmails.splice(value.length - 1, 1);
      }
    },
    onNewWhatsappPhone() {
      if (
        !this.inputWhatsappPhone ||
        this.inputWhatsappPhone.length === 0 ||
        this.recipientWhatsapp.some((r) => r === this.inputWhatsappPhone)
      ) {
        return;
      }

      let phoneNumber = this.$refs.whatsAppPhone.lazyPhone;
      if (phoneNumber && phoneNumber.valid === false) {
        // The phone number `this.inputWhatsappPhone` is not valid. Build the error message!
        let example = this.$refs.whatsAppPhone.phoneExample;
        this.whatsAppPhoneErrorMessages = [
          this.$t("alerts.wizard.deliveryMode.whatsapp.invalidPhoneNumber", { example: example }),
        ];
      } else {
        this.whatsAppPhoneErrorMessages = [];
        this.recipientWhatsapp.push(this.inputWhatsappPhone);
        this.inputWhatsappPhone = "";
      }
    },
    onWhatsappPhoneKeyDown(event) {
      if (event.code === "Enter" || event.code === "NumpadEnter" || event.code === "Tab") {
        this.onNewWhatsappPhone();
      }
    },
    filterRecipient(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
  },
  mounted() {
    this.initializeData();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-phone-input {
  display: flex;
  align-items: flex-start;
}
::v-deep .v-phone-input__country.v-input {
  min-width: 0;
  max-width: 5rem;
}
::v-deep .v-phone-input__phone.v-input {
  flex-grow: 1;
}
.teams-webhook-link {
  max-height: 60px;
}
</style>
